/** @jsx jsx */
import { jsx, Heading, Image } from 'theme-ui';
import {useRef, useState} from 'react';
import Accordion from 'components/accordion/accordion';

import PlusIcon from '../plusIcon';

export default function QandA({title, items, icon, theme}) {
    const [setActive, setActiveState] = useState("");
    const [setHight, setHightState] = useState("0px");
    const [setRotate, setRotateState] = useState("")

    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ?  "" : "rotate(135deg)"
        )
    }

    return (
        <div sx={styles.accordion__section}>
            <button 
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    background: 'none',
                    border: 'none',
                    borderBottom: '1px #A1B0BE',
                    position: 'relative',
                }}
                className={`${setActive}`} 
                onClick={toggleAccordion}
            >
                <Image
                    src={icon}
                    sx={{
                        minWidth: 'unset',
                        height: '100%',
                        maxWidth: ['67px', '100%']
                    }}
                />
                <Heading sx={styles.accordion__title}>{title}</Heading>
                <PlusIcon fill={theme} sx={{transform: setRotate}}/>
            </button>
            <div ref={content} sx={{
                backgroundColor: 'white',
                overflow: 'hidden',
                transition: 'max-height 0.6s ease',
                borderTop: '2px solid rgba(161, 176, 190, 0.2)',

            }} style={{maxHeight: `${setHight}`}}>
                <Accordion items={items} />
            </div>
        </div>  
    )
}

const styles = {
    subHead: {
        fontSize: ['18px', '32px'],
        lineHeight: 1.28,
        color: 'heading',
        marginBottom: '20px',
        backgroundImage: `-webkit-linear-gradient(left, #416585 0%, #FF8B81 85%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    accordion__section: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: [0],
    },
    accordion__title: {
        fontWeight: 600,
        fontSize: ['16px', '18px', '24px'],
        textAlign: 'left',
        ml: ['11px', '20px', '40px'],
        color: "#4F6172",
    },
    rotate: {
        transform: 'rotate(90deg)',
    },
    accordion__text: {
        fontWeight: 400,
        fontSize: 14,
        padding: '10px',
    },
    accordion__description: {
        fontSize: 14,
        width: '54%',
        lineHeight: '18.2px',
        display: ['none', 'none', 'none', 'block'],
    },
    accordion__menuCardWrap: {
        display: 'grid',
        gridGap: '16px',
        gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr'],
    },
}