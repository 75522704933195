/** @jsx jsx */
import { jsx } from 'theme-ui';
import { motion } from 'framer-motion';

export const AccordionButton = ({ children, ...rest }) => (
  <div sx={styles.buttonToggle} {...rest}>
    {children}
  </div>
);

const styles = {
  buttonToggle: {
    display: 'flex',
    color: 'headingSecondary',
    cursor: 'pointer',
    border: 'none',
    fontSize: [14, 16, 16,],
    fontWeight: 700,
    letterSpacing: -0.5,
    paddingLeft: ['10px', '17'],
    paddingRight: ['35px', '0'],
    lineHeight: [1.5, 1.8],
    '& > span': {
      position: 'absolute',
      width: 20,
      height: 20,
      borderRadius: '50%',
      top: ['20px'],
      right: ['3px', '13px'],
      '&.open:after': {
        opacity: 0,
      },
      // '&::before': {
      //   position: 'absolute',
      //   content: '""',
      //   height: '2px',
      //   width: 10,
      //   backgroundColor: 'white',
      //   top: '50%',
      //   left: '50%',
      //   transform: 'translate(-50% , -50%)',
      // },
      // '&::after': {
      //   position: 'absolute',
      //   content: '""',
      //   height: 10,
      //   width: '2px',
      //   backgroundColor: 'white',
      //   top: '50%',
      //   left: '50%',
      //   transform: 'translate(-50% , -50%)',
      //   transition: 'all 0.25s',
      // },
    },
  },
};

const variants = {
  open: {
    height: 'auto',
    marginTop: 12,
  },
  closed: { height: 0, marginTop: 0 },
};
export function AccordionContents({ isOpen, ...props }) {
  return (
    <motion.div
      initial="closed"
      animate={isOpen ? 'open' : 'closed'}
      variants={variants}
      sx={{
        overflowY: 'hidden',
        fontSize: [1, 2],
        lineHeight: 2,
        color: '#343D48',
        paddingLeft: ['30px'],
        paddingRight: '30px',
        ' > div ': {
          paddingBottom: [1, 2],
        },
      }}
      {...props}
    />
  );
}

export const AccordionItem = ({ isOpen, children, ...rest }) => (
  <div
    css={{
      overflow: 'hidden',
      padding: '19px 0',
      borderBottom: '1px solid #E5ECF4',
      position: 'relative',
      '&:last-child': {
        borderBottom: '0px solid',
      },
    }}
    {...rest}
  >
    {children}
  </div>
);

export const preventClose = (state, changes) =>
  changes.type === 'closing' && state.openIndexes.length < 2
    ? { ...changes, openIndexes: state.openIndexes }
    : changes;

export const single = (state, changes) =>
  changes.type === 'opening'
    ? { ...changes, openIndexes: changes.openIndexes.slice(-1) }
    : changes;

export const combineReducers = (...reducers) => (state, changes) =>
  reducers.reduce((acc, reducer) => reducer(state, acc), changes);
