/** @jsx jsx */
import { jsx, Container, Flex, Divider, Heading, Image } from 'theme-ui';
import SEO from 'components/seo';
import Layout from 'components/pageLayout';
import SectionHeading from '../components/section-heading'
import banner from 'assets/images/qandaBanner.png'
import QandAAccordion from 'components/accordion/qandaAccordion';

import menuHihu from 'assets/images/menuHihu.png';
import menuEMS from 'assets/images/menuEMS.png'
import hairRemoval from 'assets/images/hairRemoval.png'
import common from 'assets/images/common.png'
import { useInView } from 'react-intersection-observer'
import { keyframes } from '@emotion/react'
const wave = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

const dataWax = [
    {
      isExpanded: false,
      title: 'Q.ブラジリアンワックス脱毛とはなんですか？',
      contents: (
        <div>
          A. <br/>
          はちみつや松ヤニなどを原料としたワックス剤を、毛髪の生えた箇所に塗布し、 乾いたら毛根から一気に抜き去る脱毛方法で、ビキニライン・会陰・肛門・睾丸・陰茎を含む アンダーヘア脱毛のことを指します。
          （VIO以外のワックス剤を用いた脱毛は、単純にワックス脱毛といいます。）
        </div>
      ),
    },
    {
      isExpanded: true,
      title: 'Q.ワックス脱毛はどれくらい痛いですか',
      contents: (
        <div>
          A.<br/>
          お痛みの感じ方はかなり個人差が有り、その日の体調、毛量や毛根の強さ、 施術者の技術など様々な条件にもよります。
          しかしながら耐えられないほどの痛みではない、 と感じていただける方がほとんどです。
           ※Vゾーンや男性のヒゲ等、濃い部分ほど痛みは強い傾向にあります。
        </div>
      ),
    },
    {
      isExpanded: false,
      title: 'Q.ワックス脱毛にはどれくらいの毛の長さが必要ですか',
      contents: (
        <div>
          A. <br/>
          ５㎜から１㎝程度毛の長さがあれば脱毛可能です。 
          ただし定期的にシェービングされていたり、脱毛クリームなどを使っている方はかなり抜けにくい場合があります。 
          １ヶ月程度は毛を伸ばしてご来店をおすすめいたします。
        </div>
      ),
    },
    {
      isExpanded: false,
      title: 'Q.妊婦にワックス脱毛は可能ですか？',
      contents: (
        <div>
          A. <br/>
          安定期に入られている方で、主治医の方の許可が降りていれば可能です。 
          ご自身のご体調とご相談の上ご来店ください。
        </div>
      ),
    },
    {
      isExpanded: false,
      title: 'Q.どれくらいツルツルな状態が持続しますか？',
      contents: (
        <div>
          A. <br/>
          毛周期にも左右されますが、３日から１週間程度は ツルツルの状態が持続できます。 
          ４週間から８週間かけて元の状態になりますので、 その期間を目安にご来店をおすすめいたします。
        </div>
      ),
    },
    {
        isExpanded: false,
        title: 'Q.すぐに温泉や海水浴など行けますか？',
        contents: (
          <div>
            A. <br/>
            脱毛直後は毛穴が開いた状態になり、雑菌などが入りやすく外的刺激を受けやすいので、2,3日は入浴、激しいスポーツ、日焼け、性行為などは避けていただくほうが よろしいかと存じます。
          </div>
        ),
      },
      {
        isExpanded: false,
        title: 'Q.アフターケア方法を教えて下さい',
        contents: (
          <div>
            A.<br/>
            当日は湯船に浸かるのは避け、ぬるめのシャワー浴のみで、入浴後はお化粧水でたっぷり
            保湿をしてあげてください。尚、油分の多いクリームなどは毛穴づまりし、
            ニキビの原因となりますし、乾燥すると埋没毛（イングロウン）、
            毛嚢炎の原因になりますので、避けていただき、化粧水メインでお手入れ
            いただくのがよろしいかと存じます。
          </div>
        ),
      },
  ];

const dataHairRemove = [
{
    isExpanded: false,
    title: 'Q.どのくらいのペースで通えば毛がなくなりますか?',
    contents: (
    <div>
        A.<br/>
        前提として、現在法律上でも「永久脱毛」という言葉は使えません。現段階の技術では一生一本も毛を生やさせなくする脱毛方法は確立されていません。しかしながら美容脱毛機械の進化はめざましく、ある程度お手入れが必要ない程度の減毛は可能です。お客様のゴール、毛量、どのくらいの頻度/周期でご来店していただけるか、どの程度の出力（≒痛み
        で施術するかや、施術部位にもよりますので、詳細はカウンセリング時にお話できればと存じます。
    </div>
    ),
},
{
    isExpanded: true,
    title: '全身脱毛は可能ですか?',
    contents: (
    <div>
        A.<br/>
        粘膜部分、目の近く、男性器の睾丸・陰茎部分、甲状腺付近以外の全身に照射可能です。
    </div>
    ),
},
{
    isExpanded: false,
    title: 'Q.痛みはありますか?',
    contents: (
    <div>
        A.<br/>
        関節部分や毛の濃い箇所、日焼け肌などはお痛みが強く出る場合もありますが、冷却システムを採用しマイナス10度に冷却しながら光を照射する従来よりもかなり痛みを軽減することが可能となっております。
    </div>
    ),
},
{
    isExpanded: false,
    title: 'Q.どの様な原理で毛が減っていくのですか？',
    contents: (
    <div>
        A.<br/>
        従来のIPL脱毛（毛穴の黒いものに反応する脱毛機械）の性能に加え、発毛因子となる細胞（バルジ領域）を弱い熱で広範囲にわたって弱らせるSHR脱毛の機能を併せ持った複合機を取り扱っており、その両方の特徴をもった特殊な光を照射することで毛髪を再生しづらくさせます。
    </div>
    ),
},
{
    isExpanded: false,
    title: 'Q.脱毛前後で気をつけたほうがいいことはありますか？',
    contents: (
    <div>
        A.<br/>
        光脱毛のみのご利用の場合、来店【前日】に施術予定箇所の剃毛をお願いいたします。施術直後は汗をかく行為、施術前後数日はなるべく日焼けを避けていただき、十分に保湿を行ってください。

    </div>
    ),
},
{
    isExpanded: false,
    title: 'Q.ブラジリアンワックス脱毛と光脱毛はどちらがおすすめですか？',
    contents: (
        <div>
        A.<br/>
        ワックス脱毛、光脱毛に限らず、医療レーザー脱毛、ニードル脱毛、フラッシュ脱毛など、現在、多様な脱毛方法が存在しますが、そのそれぞれメリット・デメリットがございます。お客さまのライフスタイルや目指す脱毛のゴール、ご予算、サロンに通える期間、痛みをどの程度抑えたいか等の情報を複合的に判断してご提案しますので、詳細はカウンセリング時にお話できればと存じます。
        </div>
    ),
    },
];

const dataHifu = [
    {
        isExpanded: false,
        title: 'Q.ハイフとはなんですか?',
        contents: (
        <div>
            A.<br/>
            ハイフ（HIFU）とは、「High Intensity Focused Ultrasound（高密度焦点式超音波）」の略称で、リフトアップのための施術のひとつです。ハイフが世に出てくるまで、たるみの治療は外科的なものがほとんどでしたが、ハイフの出現によって、メスを使わずにたるみの改善ができるようになりました。お顔だけに限らず、ボディへの施術も可能で、ボディハイフは「メスを使わない脂肪吸引」ともいわれています。
        </div>
        ),
    },
    {
        isExpanded: true,
        title: 'Q.どういった人が向いていますか?',
        contents: (
        <div>
            A.<br/>
            著しくたるみの症状が進んでいる方、逆に全くたるみがない方は向いていない、と一般的には言われています。ある程度脂肪があり、多少たるみが気になり始めてきた方の、たるみ予防という側面も大いにあります。
        </div>
        ),
    },
    {
        isExpanded: false,
        title: 'Q.痛みはありますか?',
        contents: (
        <div>
            A.<br/>
            個人差もありますが、ほぼ痛みは有りません。出力をあげると、あたたかさとチリチリとした痒みにも似た、わずかな痛みがありますが、推奨出力では痛みを感じることはほぼありません。（個人差はあります）
        </div>
        ),
    },
    {
        isExpanded: false,
        title: 'Q.どのくらいの頻度で通えばいいのですか？',
        contents: (
        <div>
            A.<br/>
            たるみ予防という側面もありますので4週間に1回程度の施術を推奨しております。
        </div>
        ),
    },
    {
        isExpanded: false,
        title: 'Q.施術前、施術後で気をつけることはありますか？',
        contents: (
        <div>
            A.<br/>
            施術前は女性の場合はノーメイクの状態でお越しください。男性でも日焼け止めなどは落としていただきます。施術後、とくにボディハイフ後は老廃物をたくさん流していただくと効果的なので、半身浴や軽い運動をおすすめします。アルコール類は吸収しやすいのでおすすめしません。ダウンタイムはほぼないので直後から軽いお化粧が可能です。
    
        </div>
        ),
    },
    ];

const dataEMS = [
    {
        isExpanded: false,
        title: 'Q.EMSとはなんですか？',
        contents: (
        <div>
            A.<br/>
            EMSとは「Electrical Muscle Stimulation」 の略で、日本語に直すと「電気的筋刺激装置」となります。もともとはリハビリやアスリートのトレーニングを目的として作られました。現在ではリハビリはもちろん、疼痛緩和（痛みの緩和）や痩身（美容）を目的として使用されています。
        </div>
        ),
    },
    {
        isExpanded: true,
        title: 'Q.食前食後などEMSをするタイミングは？',
        contents: (
        <div>
            A.<br/>
            筋トレ目的なら食前は避けてください。食後二時間以上空いているとこが理想的です。美容目的の場合は食前に行ってください。ただし直後は吸収率が上がるので食事内容にはご注意ください。（糖質や脂が多いものは避ける方がベター）
        </div>
        ),
    },
    {
        isExpanded: false,
        title: 'Q.どのくらいのペースで何回くらい通えばいいのですか？',
        contents: (
        <div>
            A.<br/>
            EMSは筋トレと同じです。お客様それぞれにゴールがあると思いますのでカウンセリングして設定していきましょう。筋トレ目的の場合は週3回、徐々に筋肉がついてきたら週二回など調整していきます。もちろん普段の食事内容を気をつけていただいたり軽い運動をすると効果が早く出る場合が多いです。
        </div>
        ),
    },
    ];

const dataCommon = [
{
    isExpanded: false,
    title: 'Q.生理中、妊娠中の施術について',
    contents: (
    <div>
        A.<br/>
        光脱毛、ハイフ（お腹周り）、EMSは生理中、妊娠中の方、授乳中の方は施術不可です。
        ワックス脱毛に限り、主治医と相談の上、施術可能です。
    </div>
    ),
},
{
    isExpanded: true,
    title: 'Q.別機械の同日施術は可能ですか？',
    contents: (
    <div>
        A.<br/>
        脱毛とハイフ・EMSの機械は相性が悪いため同日施術不可です。
        ハイフとEMSは可能ですし、同時に行うことで相乗効果もありますのでオススメです。
        <br/>
        脱毛とハイフは2週間程度施術日程を空けて頂く必要があります。
        ただし、例えばVIO脱毛した直後に顔のハイフをしたい、など施術箇所がある程度離れている場合は施術可能な場合がありますので、都度ご相談ください。
    </div>
    ),
},
{
    isExpanded: false,
    title: 'Q.どのくらいのペースで何回くらい通えばいいのですか？',
    contents: (
    <div>
        A.<br/>
        EMSは筋トレと同じです。お客様それぞれにゴールがあると思いますのでカウンセリングして設定していきましょう。筋トレ目的の場合は週3回、徐々に筋肉がついてきたら週二回など調整していきます。もちろん普段の食事内容を気をつけていただいたり軽い運動をすると効果が早く出る場合が多いです。
    </div>
    ),
},
];
export default function QandA() {
    const [ref, inView] = useInView({
        rootMargin: "-150px 0px",
        triggerOnce: true,
    });

    return (
      <Layout>
        <SEO title="MarMot! Beauty | Q&A" />
        <Container sx={{
            pt: '120px',
            pb: ['40px', '150px'],
        }}>
            <SectionHeading title="Q&A" sx={{letterSpacing: '10px'}}/>
            <Image 
                ref={ref} 
                src={banner} 
                variant="sectionBanner" 
                css={{
                    opacity: inView ? '1' : '0',
                    animation: inView ? `${wave} ease-out .3s` : 0,
                }}
                />
            <Flex sx={{
                alignItems: 'center',
                px: ['0', '50px']
            }}>
                <Heading as="h4" sx={styles.subHead}>よくあるご質問</Heading>
                <Divider />
            </Flex>

            <QandAAccordion title="ワックス脱毛に関して" items={dataWax} icon={hairRemoval} theme="#61A2DD"/>
            <QandAAccordion title="光脱毛に関して" items={dataHairRemove} icon={hairRemoval} theme="#61A2DD"/>
            <QandAAccordion title="ハイフに関して" items={dataHifu} icon={menuHihu} theme="#FF8B81"/>
            <QandAAccordion title="EMSに関して" items={dataEMS} icon={menuEMS} theme="#FF8B81"/>
            <QandAAccordion title="共通の質問" items={dataCommon} icon={common} theme="#FF8B81"/>
        </Container>
      </Layout>
    );
  }
  
const styles = {
    subHead: {
        fontSize: ['18px', '32px'],
        lineHeight: 1.28,
        color: 'heading',
        marginBottom: '20px',
        backgroundImage: `-webkit-linear-gradient(left, #416585 0%, #FF8B81 85%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
}